import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

import 'alpinejs'
import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
AOS.init();


document.addEventListener('turbolinks:load', () => {
  const sweetScroll = new SweetScroll({ /* some options */ });
}, false);