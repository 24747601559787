import { Controller } from 'stimulus'

import Swiper, { Autoplay, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, EffectFade])

export default class extends Controller {
  static targets = []

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      duration: 1000,
      fadeEffect: {
        crossfade: true
      },
      autoplay: {
        delay: 10000,
      },
    })
  }
}

