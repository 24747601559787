import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['next', 'prev',  'container']

  connect() {

    console.log("connected")

    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView:1,
      centeredSlides: true,
      spaceBetween: 32,
      // autoplay: {
      //   delay: 10000,
      // },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },

      breakpoints: {
        640: {
          slidesPerView: 1.66,
        },
        1024: {
          slidesPerView: 3.66,
        }
      }
    })
  }
}
