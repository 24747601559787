import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button', 'text', 'contactDetails']

  connect() {

  }

  toggleCard() {
    this.buttonTarget.classList.toggle('rotate-180')

    if (!(this.contentTarget.classList.contains('active'))) {
      this.contentTarget.classList.toggle('active')

      setTimeout(() => {
        this.textTarget.classList.toggle('opacity-0')
        this.contactDetailsTarget.classList.toggle('opacity-0')
      }, 100);
    } else {

      this.textTarget.classList.toggle('opacity-0')
      this.contactDetailsTarget.classList.toggle('opacity-0')

      setTimeout(() => {
        this.contentTarget.classList.toggle('active')
      }, 100);
    }
  }
}
