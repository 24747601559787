import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'


import "@fontsource/darker-grotesque/400.css"
import "@fontsource/darker-grotesque/500.css"
import "@fontsource/darker-grotesque/600.css"
import "@fontsource/darker-grotesque/700.css"

import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))